import React from "react";
import YoutubeEmbed from "../components/YouTubeEmbed";

function Video() {
    return (
      <div>
       <YoutubeEmbed embedId="T8WBbF87fKE" />
      </div>
    );
  }
  
  export default Video;
  