import * as React from 'react';
import Blog from './blog/Blog';

function Home() {
    return (
      <Blog/>
    );
  }
  
  export default Home;
  